import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import timediff from "timediff";
import showdown from "showdown";
import markdownToTxt from "markdown-to-txt";
import Helmet from "react-helmet";
import axios from "axios";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Item from "../../components/item";

import {
  Breadcrumbs,
  Button,
  Hero,
  SectionHeader,
  Link,
} from "../../components/common";

import { leadingZero, shuffleArray } from "../../utils";

// import { useAuth } from "../../hooks/useAuth";
import ProductGallery from "./gallery";

import "./style.css";
import FreeDownloadPopup from "../../components/freeDownloadPopup";

const converter = new showdown.Converter();

function ProductPage({
  pageContext: {
    data: { attributes: product = {} },
    relatedProducts,
  },
  location,
}) {
  if (!product) {
    return null;
  }
  const { state: locationState } = location;

  // const { isAuthenticated, state } = useAuth();
  const containerRef = useRef();
  const sidebarRef = useRef();
  const [timeDiff, setTimeDiff] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const [itemAttributePopupIsOpen, setItemAttributePopupIsOpen] = useState(
    false
  );
  const [isFetching, setIsFetching] = useState(false);
  const onItemAttributePopupClose = () => {
    setItemAttributePopupIsOpen(false);
  };
  const showDateTimerCountdown =
    product?.SalePrice &&
    product.SaleEndDate &&
    new Date(product.SaleStartDate) <= new Date(Date.now()) &&
    new Date(product.SaleEndDate) >= new Date(Date.now());
  let freeDownloadBannerTimeOut = null;
  useEffect(() => {
    let timeDiffTimeout = null;
    if (showDateTimerCountdown && product.SaleEndDate) {
      timeDiffTimeout = setTimeout(() => {
        setTimeDiff(
          timediff(new Date(Date.now()), new Date(product.SaleEndDate), {
            units: {
              days: true,
              hours: true,
              minutes: true,
              seconds: true,
            },
          })
        );
      }, 1000);
    }

    return () => {
      clearTimeout(timeDiffTimeout);
      clearTimeout(freeDownloadBannerTimeOut);
    };
  });

  let lemonSqueezyCheckoutRefreshed = false;

  useEffect(() => {
    const { current } = sidebarRef;

    if (typeof window !== "undefined" && current) {
      window.addEventListener("scroll", onWindowScroll);
    }

    if (
      typeof window?.LemonSqueezyCheckout !== "undefined" &&
      !lemonSqueezyCheckoutRefreshed
    ) {
      window.LemonSqueezyCheckout.refresh();
      lemonSqueezyCheckoutRefreshed = true;
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("scroll", onWindowScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (
      typeof window?.LemonSqueezyCheckout !== "undefined" &&
      !lemonSqueezyCheckoutRefreshed
    ) {
      window.LemonSqueezyCheckout.refresh();
      lemonSqueezyCheckoutRefreshed = true;
    }
  });

  const onWindowScroll = () => {
    const { current: container } = containerRef;
    const { current: sidebar } = sidebarRef;

    const {
      y: yContainer,
      top: topContainer,
      height: heightContainer,
    } = container.getBoundingClientRect();
    // const { y: ySidebar } = sidebar.getBoundingClientRect();
    const toTheBottom = heightContainer - window.scrollY - 160;
    if (yContainer <= 32 && toTheBottom >= 0) {
      sidebar.style.justifyContent = "flex-start";
      sidebar.classList.add("relative");
      sidebar.style.top = `${-topContainer + 32}px`;
      // sidebar.style.right = 0;
    } else if (toTheBottom < 0) {
      sidebar.style.top = "unset";
      sidebar.style.justifyContent = "flex-end";
    } else {
      sidebar.style.justifyContent = "flex-start";
      if (!sidebar.classList.contains("dev-vg-sidebar--has-sale")) {
        sidebar.classList.remove("relative");
      }
    }
  };

  const tags = String(product?.Tags || "").split(", ");

  const text = product.Description;
  const plainText = markdownToTxt(text);
  const description = String(plainText).replace(/\n/g, " ").substring(0, 160);
  const html = converter.makeHtml(text);
  const isProductDisabled = product?.Disabled;
  const handleFreeDownload = async (bucketName, key) => {
    if (!isFetching) {
      setIsFetching(true);

      const { data } = await axios(
        `https://vg-download-link.vackground.workers.dev?bucketName=${bucketName}&fileName=${key}`
      );

      if (data.success) {
        const { data: url } = data;

        const a = document.createElement("a");
        a.href = url;
        a.download = url.split("/").pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        freeDownloadBannerTimeOut = setTimeout(() => {
          setItemAttributePopupIsOpen(true);
          setIsFetching(false);
        }, 1000);
      } else {
        setIsFetching(false);
      }
    }
  };
  const renderSidebar = () => {
    return (
      <>
        {renderDownloadBtn()}
        <div className="py-3 flex flex-col items-center text-center gap-y-1">
          <div className="flex flex-col gap-y-1">
            {product.Price === 0 ? (
              <>
                <div>
                  <a
                    className="text-ui-gray-plus-4 text-pre-tiny cursor-pointer underline hover:no-underline"
                    onClick={(e) => {
                      e.preventDefault();

                      setItemAttributePopupIsOpen(true);
                    }}
                  >
                    How to Attribute?
                  </a>
                </div>
              </>
            ) : (
              <>
                {product?.DemoFileKey ? (
                  <div>
                    <button
                      onClick={() =>
                        handleFreeDownload(
                          process.env.GATSBY_CLOUDFLARE_DEMO_FILES_BUCKET_NAME,
                          product?.DemoFileKey
                        )
                      }
                      className="text-ui-gray-plus-4 underline hover:no-underline leading-6 tracking-tightly text-pre-tiny"
                    >
                      Download Free Demo
                    </button>
                  </div>
                ) : (
                  <div>
                    <Link
                      className="text-ui-gray-plus-4 leading-6 tracking-tightly text-pre-tiny"
                      to="/why-us"
                    >
                      Why Buy From Us?
                    </Link>
                  </div>
                )}
              </>
            )}
            <Link
              className="text-ui-gray-plus-4 leading-6 tracking-tightly text-pre-tiny"
              to="/license"
            >
              View License
            </Link>
          </div>
        </div>
        {!!showDateTimerCountdown && (
          <div
            style={{ color: "#FF216B" }}
            className="flex items-center justify-center text-center text-lg gap-x-8 font-bold py-5 rounded-2xl border border-ui-gray-plus-1 relative mb-6"
          >
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 translate-y-1/2 bg-background-bright px-2 text-ui-gray-plus-4 font-bold text-xs">
              SALE ENDS IN
            </span>
            <div>
              {leadingZero(timeDiff.days)}
              <span className="block text-tiny text-content-gray">days</span>
            </div>
            <div>
              {leadingZero(timeDiff.hours)}
              <span className="block text-tiny text-content-gray">hrs</span>
            </div>
            <div>
              {leadingZero(timeDiff.minutes)}
              <span className="block text-tiny text-content-gray">mins</span>
            </div>
            <div>
              {leadingZero(timeDiff.seconds)}
              <span className="block text-tiny text-content-gray">secs</span>
            </div>
            <span className="absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background-bright px-2 text-ui-gray-plus-4 font-bold text-xs uppercase whitespace-nowrap">
              {`Hurry Up & save - $${product.Price - product.SalePrice} (${(
                100 -
                (product.SalePrice / product.Price) * 100
              ).toFixed(2)}%)`}
            </span>
          </div>
        )}
        <div
          style={{ borderColor: "#D1CEE0" }}
          className="flex border justify-center border-brand-primary p-6 rounded-lg"
        >
          <div className="flex flex-col gap-y-2">
            {product.Price === 0 ? (
              <>
                <div className="flex items-center">
                  <i className="vg-award text-xl text-ui-gray-plus-2" />
                  <span className="ml-4 font-normal text-pre-tiny tracking-tightly text-ui-gray-plus-4">
                    Ensured Premium Quality
                  </span>
                </div>
                <div className="flex items-center">
                  <i className="vg-infinity text-xl text-ui-gray-plus-2" />
                  <span className="ml-4 font-normal text-pre-tiny tracking-tightly text-ui-gray-plus-4">
                    Keep & Use Forever
                  </span>
                </div>
                <div className="flex items-center">
                  <i className="vg-circle-check text-xl text-ui-gray-plus-2" />
                  <span className="ml-4 font-normal text-pre-tiny tracking-tightly text-ui-gray-plus-4">
                    Personal & Commercial Use
                  </span>
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center">
                  <i className="vg-infinity text-xl text-ui-gray-plus-2" />
                  <span className="ml-4 font-normal text-pre-tiny tracking-tightly text-ui-gray-plus-4">
                    Buy Once, Use Unlimited
                  </span>
                </div>
                <div className="flex items-center">
                  <i className="vg-bell text-xl text-ui-gray-plus-2" />
                  <span className="ml-4 font-normal text-pre-tiny tracking-tightly text-ui-gray-plus-4">
                    Instant Digital Download
                  </span>
                </div>
                <div className="flex items-center">
                  <i className="vg-shield text-ui-gray-plus-2" />
                  <span className="ml-4 font-normal text-pre-tiny tracking-tightly text-ui-gray-plus-4">
                    60-Day Refund Guarantee
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        {product?.Price === 0 ? (
          <div
            style={{ maxWidth: 224 }}
            className="flex items-center mx-auto justify-center text-center text-content-gray leading-4 tracking-normal font-medium text-xs py-2 px-4"
          >
            <span className="text-ui-gray-plus-3">
              <>
                By downloading this, you agree to our{" "}
                <Link to="/license">license</Link>,{" "}
                <Link to="/terms">terms</Link> &{" "}
                <Link to="/privacy">privacy policy</Link>
              </>
            </span>
          </div>
        ) : (
          <div className="flex items-center flex-col justify-center text-xs py-2 leading-4 tracking-normal font-medium">
            <span className="text-ui-gray-plus-3">
              VAT may be charged by your country law
            </span>
            <span className="text-ui-gray-plus-3">
              Secure Payment by LemonSqueezy
            </span>
          </div>
        )}
        {isProductDisabled && (
          <div className="flex flex-col gap-y-2 items-center border border-semantic-error p-6 rounded-lg">
            <div className="text-semantic-error text-tiny text-center">
              Sorry, this product is not available now, please check back later
              or{" "}
              <Link className="text-semantic-error" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderDownloadBtn = () => {
    if (product.Price === 0) {
      return (
        <Button
          className="dev-vg-btn__free-download"
          isLoading={isFetching}
          preventDefault
          text="$0 - Download Now"
          subText="Free With Attribution"
          hasMinWidth={false}
          onClick={() =>
            handleFreeDownload(
              process.env.GATSBY_CLOUDFLARE_FREE_FILES_BUCKET_NAME,
              product?.FreeFileKey
            )
          }
          disabled={isProductDisabled}
        />
      );
    }

    return (
      <>
        {!!showDateTimerCountdown && (
          <span className="absolute bg-semantic-alert-minus-1 z-10 left-1/2 transform -translate-x-1/2 bottom-full translate-y-1/2 text-semantic-alert-plus-1 py-0 px-2 rounded-sm font-bold text-xs">{`SALE - ${(
            100 -
            (product.SalePrice / product.Price) * 100
          ).toFixed(1)}% OFF`}</span>
        )}

        <Button
          preventDefault
          // className="lemonsqueezy-button"
          newTab
          href={`https://vackground.lemonsqueezy.com/checkout/buy/${product?.ProductCheckoutCode}`}
          plainText={`$${product.Price} - Purchase Now`}
          text={
            <>
              <span
                className={classnames("text-brand-primary-on", {
                  "opacity-75 line-through": showDateTimerCountdown,
                })}
              >
                ${product.Price}
              </span>{" "}
              {!!showDateTimerCountdown && <>${product.SalePrice} </>} -
              Purchase Now
            </>
          }
          subText="Get Instant Access"
          hasMinWidth={false}
          disabled={isProductDisabled}
        />
      </>
    );
  };

  return (
    <Layout>
      <SEO
        keywords={["vackground"].concat(tags)}
        title={product?.SEOTitle || product.Name}
        description={product?.MetaDescription || description}
        image={
          product?.FeaturedImage?.data?.attributes?.localFile?.childImageSharp
            ?.gatsbyImageData?.images?.fallback?.src
        }
        metaKeywords={product?.MetaKeywords}
        metaRobots={product?.MetaRobots}
        path={product?.CanonicalURL || location?.pathname}
      />
      <Helmet>
        <script
          src="https://app.lemonsqueezy.com/js/checkout.js"
          defer
        ></script>
        <script async defer>
          {`
            let checkoutCreated = false;

            const createCheckout = () => {
              if( window?.createLemonSqueezyCheckout ) {
                window.createLemonSqueezyCheckout();
                checkoutCreated = true;
              }
            }
            
            const interval = setInterval(() => {
              if(!checkoutCreated) {
                createCheckout();
              } else {
                clearInterval(interval);
              }
            }, 100);
          `}
        </script>
      </Helmet>
      {product?.Price === 0 && (
        <FreeDownloadPopup
          productName={product?.Name}
          isOpen={itemAttributePopupIsOpen}
          onClose={onItemAttributePopupClose}
        />
      )}
      <section className="md:mt-16 mt-7 md:mb-24 mb-12">
        <Hero
          title={product.Name}
          action={
            <div className="flex items-center justify-center md:mt-2 mt-4">
              <Breadcrumbs
                lists={[
                  {
                    title: "Home",
                    to: "/",
                  },
                  product?.categories?.data?.length
                    ? {
                        title:
                          locationState?.Name ||
                          product?.categories?.data?.[0]?.attributes?.Name,
                        to: `/category/${
                          locationState?.URL ||
                          product?.categories?.data?.[0]?.attributes?.URL
                        }`,
                      }
                    : {
                        title: "All Categories",
                        to: `/categories`,
                      },
                ]}
              />
            </div>
          }
        />
      </section>
      <div
        style={{
          boxShadow:
            "0px -20px 80px rgba(36, 30, 82, 0.08), 0px -15px 40px rgba(36, 30, 82, 0.08)",
        }}
        className="hidden md:flex items-center justify-between lg:hidden py-4 px-8 bg-ui-gray-minus-3 fixed bottom-0 left-0 w-full z-10"
      >
        <div className="text-ui-gray-plus-4 text-lg font-bold">
          {product.Name}
        </div>
        <div
          className={classnames({
            relative: showDateTimerCountdown,
          })}
        >
          {renderDownloadBtn()}
        </div>
      </div>
      <section
        ref={containerRef}
        className="flex lg:gap-8 lg:flex-nowrap flex-wrap md:mb-40"
      >
        <div className="dev-vg-product-container flex-1 lg:w-auto w-full">
          <ProductGallery images={product.GalleryImages.data} />
          <div className="lg:px-16 lg:py-10 px-1 md:py-12 py-6 dev-vg-product-description">
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
        <div
          ref={sidebarRef}
          style={{ width: 320 }}
          className={classnames(
            "dev-vg-sidebar lg:flex flex-col gap-y-4 md:w-auto w-full hidden",
            {
              "dev-vg-sidebar--has-sale relative": showDateTimerCountdown,
            }
          )}
        >
          {renderSidebar(true)}
        </div>
        <div
          className={classnames(
            "dev-vg-sidebar lg:hidden flex flex-col gap-y-4 mx-auto md:w-80 max-w-xs w-full md:mb-60 mb-44",
            {
              "dev-vg-sidebar--has-sale relative": showDateTimerCountdown,
            }
          )}
        >
          {renderSidebar(false)}
        </div>
      </section>
      {!!relatedProducts.length && (
        <section className="md:mb-16 mb-16">
          <div className="md:mb-24 mb-12">
            <SectionHeader
              customTopTitleClassNames={{
                fontSize: "text-lg",
                lineHeight: "leading-6",
              }}
              topTitle="Related Products"
              customTitleClassNames={{
                fontSize: "lg:text-4xl text-2.25xl",
              }}
              title="You May Like These"
            />
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
            {shuffleArray(relatedProducts)
              ?.slice(0, 8)
              .map((product) => (
                <Item
                  key={product._id}
                  title={product.Name}
                  hidePricing
                  price={product.Price}
                  salePrice={product.SalePrice}
                  featuredImage={
                    product.FeaturedImage.data.attributes.localFile
                  }
                  url={product.URL}
                  minVersion
                />
              ))}
          </div>
        </section>
      )}
    </Layout>
  );
}

ProductPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default ProductPage;
