import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./freeDownloadPopup.css";

const FreeDownloadPopup = ({ productName, isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  let copyTextTimout = null;
  const DEFAULT_COPY_BUTTON_TEXT = "Copy HTML";
  const [copyButtonText, setCopyButtonText] = useState(
    DEFAULT_COPY_BUTTON_TEXT
  );

  useEffect(() => {
    return () => {
      clearTimeout(copyTextTimout);
    };
  }, []);

  const productNameInOneWord = String(productName).split(" ")[0];

  const handleCopy = (e) => {
    e.preventDefault();

    setCopyButtonText("Copied");

    if (typeof window !== "undefined") {
      navigator.clipboard.writeText(
        `<a href="${location.href}?ref=free-attribute">Abstract</a> background image by <a href="https://vackground.com?ref=free-attribute">Vackground</a>`
      );
    }

    copyTextTimout = setTimeout(() => {
      setCopyButtonText(DEFAULT_COPY_BUTTON_TEXT);
    }, 2000);
  };

  return (
    <div className="dev-vg-free-popup-download-container flex items-center justify-center fixed left-0 top-0 w-full h-screen z-20">
      <button onClick={onClose} className="block fixed w-full h-screen" />
      <div className="relative flex-1 dev-vg-free-popup-download-container__popup bg bg-background-white py-20 px-24 rounded-2xl">
        <button onClick={onClose} className="absolute top-4 right-4">
          <i className="vg-x" />
        </button>
        <h3 className="text-center font-bold text-3xl leading-10 tracking-tighteer text-ui-gray-plus-4 mb-8">
          Attribute Our Hard Work
          <br />
          Wherever You Use This Background
        </h3>
        <div
          className="dev-vg-free-popup-download-container__popup__copy-text flex items-stretch w-full"
          onCopy={handleCopy}
        >
          <div className="flex-1 bg-ui-gray-minus-3 py-5 px-8 rounded-l-xl">
            <span className="underline">{productNameInOneWord}</span> background
            image by <span className="underline">Vackground</span>
          </div>
          <button
            className="dev-vg-free-popup-download-container__popup__copy-text__action inline-flex items-center text-background-white rounded-r-xl text-center justify-center text-pre-lg font-bold leading-7 tracking-tightsy"
            style={{
              background:
                "radial-gradient(100% 100% at 50% 0%, #725cff 0%, #543fe0 100%)",
            }}
            onClick={handleCopy}
          >
            <i className="vg-copy mr-2 text-background-white" />{" "}
            {copyButtonText}
          </button>
        </div>
        <div className="mt-4 flex items-center justify-center gap-4">
          <div className="text-ui-gray-plus-3 font-normal text-pre-tiny leading-6 tracking-tightly">
            Or shoutout on
          </div>
          <div className="flex items-center gap-6">
            <a
              target="__blank"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=${
                typeof window !== "undefined" ? location.href : ""
              }`}
              className="cursor-pointer"
            >
              <i className="vg-facebook text-ui-gray-plus-2 hover:text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/intent/tweet?url=${
                typeof window !== "undefined" ? location.href : ""
              }&text=Thanks+for+making+this+background+available+freely+on+%40vackground`}
              className="cursor-pointer"
            >
              <i className="vg-twitter text-ui-gray-plus-2 hover:text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href={`https://www.instagram.com/vackgroundhq/`}
              className="cursor-pointer"
            >
              <i className="vg-instagram text-ui-gray-plus-2 hover:text-ui-gray-plus-3" />
            </a>
            <a
              target="__blank"
              rel="noopener noreferrer"
              href={`https://pinterest.com/pin/create/button/?url=${
                typeof window !== "undefined" ? location.href : ""
              }&description=Thanks+for+making+this+background+available+freely+on+%40vackground`}
              className="cursor-pointer"
            >
              <i className="vg-pinterest text-ui-gray-plus-2 hover:text-ui-gray-plus-3" />
            </a>
          </div>
        </div>
        <div className="mt-8 text-center text-ui-gray-plus-4 font-normal text-lg leading-8 tracking-tightsy">
          We did countless hours of hard work to create this background pack.
          Show your love by giving a credit link to this page or shoutout on
          social media. Thanks for being awesome.
        </div>
      </div>
    </div>
  );
};

FreeDownloadPopup.propTypes = {
  productName: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FreeDownloadPopup;
