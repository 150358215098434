import React, { Component } from "react";
import Slider from "react-slick";
import PropTypes from "prop-types";
import classnames from "classnames";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import "./gallery.css";

function Arrow({ onClick, type }) {
  return (
    <div
      style={{
        height: 48,
        width: 48,
        fontSize: 20,
        top: "50%",
        transform: "translateY(-50%)",
        right: type === "prev" ? "auto" : 32,
        left: type === "prev" ? 32 : "auto",
      }}
      className="flex absolute z-10 items-center justify-center bg-background-white rounded-full cursor-pointer dev-vg-slider-arrow"
      onClick={onClick}
    >
      <i
        style={{
          transform: type === "prev" ? "rotate(180deg)" : "rotate(0deg)",
        }}
        className={`vg-arrow-right`}
      />
    </div>
  );
}

Arrow.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
};

class ProductGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
      activeSlide: 0,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }
  render() {
    const { images } = this.props;
    const settings = {
      nextArrow: <Arrow />,
      prevArrow: <Arrow type="prev" />,
    };
    return (
      <div className="flex flex-col dev-vg-product-gallery">
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          className="rounded-lg"
          {...settings}
        >
          {images?.map((image) => (
            <div
              style={{ height: 700 }}
              key={image.id}
              className="dev-vg-product__image-container dev-vg-product__image-container--hero rounded-xl"
            >
              <GatsbyImage
                className="dev-vg-product-image__image object-cover rounded-xl w-full"
                image={getImage(image.attributes.localFile)}
                alt="vg-gallery-cover"
              />
            </div>
          ))}
        </Slider>
        <Slider
          // asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          // slidesToShow={7}
          // slidesToScroll={1}
          // centerMode={true}
          // swipeToSlide={true}
          // focusOnSelect={true}
          adaptiveHeight={true}
          // variableWidth={120}
          // arrows={false}
          // slidesToShow: 3,
          // slidesToScroll: 1,
          // asNavFor: '.slider-for',
          // dots: true,
          // centerMode: true,
          // focusOnSelect: true
          slidesToShow={5}
          slidesToScroll={1}
          asNavFor={this.state.nav1}
          centerMode={true}
          focusOnSelect={true}
          afterChange={(currentSlide) => this.setState({ currentSlide })}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {images?.map((image, index) => (
            <div
              key={image.id}
              className={classnames(
                "dev-vg-product__image-container dev-vg-product__image-container--gallery cursor-pointer rounded-lg",
                {
                  "dev-vg-product__image-container--active relative":
                    (this.state.currentSlide || 0) === index,
                }
              )}
            >
              <GatsbyImage
                image={getImage(image.attributes.localFile)}
                className={classnames(
                  "dev-vg-product__image object-cover rounded-lg transition-all duration-200 mx-3 my-6",
                  {
                    "dev-vg-product__image--active opacity-90 relative":
                      (this.state.currentSlide || 0) === index,
                    "opacity-100": (this.state.currentSlide || 0) !== index,
                  }
                )}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  }
}

ProductGallery.propTypes = {
  images: PropTypes.array,
};

export default ProductGallery;
